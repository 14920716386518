import { showToast } from '@/store'
import { checkIsPublicDomain, isWujie } from '@/utils/env'
import { lzcAPIGateway } from '@lazycatcloud/sdk'
import { AppCommon } from '@lazycatcloud/sdk/dist/extentions/app_common'
import { DeviceInfo } from '@lazycatcloud/sdk/dist/localdevice/device'
import { AppAccessPolicy } from '@lazycatcloud/sdk/dist/sys/ingress'
import { AppInfo } from '@lazycatcloud/sdk/dist/sys/package_manager'
import { LazycatAppMetaModel } from '@type/models'
import { axios } from './axios'

let _lzcInstanceGateway: lzcAPIGateway | null = null
let _deviceIno: DeviceInfo | null = null

/**
 * `lzcApi` 单实例
 */
function getLzcApiInstnace(): lzcAPIGateway | null {
  if (!!_lzcInstanceGateway) return _lzcInstanceGateway
  try {
    if (!checkIsPublicDomain()) {
      // 公网情况下不会初始化
      if (isWujie()) {
        _lzcInstanceGateway = new lzcAPIGateway(window.__WUJIE_PUBLIC_PATH__)
      } else {
        _lzcInstanceGateway = new lzcAPIGateway()
      }
      ;(window as any).cc = getLzcApiInstnace()
      return _lzcInstanceGateway
    }
  } catch (error) {
    showToast('初始化grpc实例失败', 2400)
    console.error(error)
  }
  return null
}

/**
 * 获取当前设备信息
 */
async function getCurrentDeviceInfo() {
  if (!!_deviceIno) return _deviceIno
  const result = await (await getLzcApiInstnace()!.currentDevice)?.device.Query({})
  if (!!result) {
    _deviceIno = result
    return result
  }
  return null
}

/**
 * 获取当前设备的操作系统
 */
export async function getCurrentDeviceOS() {
  const info = await getCurrentDeviceInfo()
  if (!!info) return info.OS.toLowerCase()
  return ''
}

/**
 * 检查是否允许安装应用
 */
export async function checkAllowInstallApp(): Promise<boolean> {
  const session = await getLzcApiInstnace()?.session
  if (!session) return false
  const { uid } = session
  const permissions = getLzcApiInstnace()?.pkgm?.GetUserPermissions({ uid })
  if (!permissions) return false
  return (await permissions).allowInstallApp
}

/**
 * 查询应用策略
 */
export async function queryAppAccessPolicy(): Promise<AppAccessPolicy | false> {
  const session = await getLzcApiInstnace()?.session
  if (!session) return false
  const { uid } = session
  const data = await getLzcApiInstnace()?.ac.QueryAppAccessPolicy({
    uid,
  })
  if (!data) return false
  return data
}

/**
 * 暂停安装应用
 */
export async function pauseInstallApplication(appid: string): Promise<boolean> {
  try {
    await getLzcApiInstnace()!.pkgm.PauseAppDownload({
      appid,
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * 查询盒子已安装的某个 app 应用信息
 * 如果不传 `pkgId` 则默认查询所有已安装的应用
 */
export async function queryBoxInstalledApplication(pkgId: string): Promise<AppInfo>
export async function queryBoxInstalledApplication(pkgId?: string): Promise<AppInfo[]>
export async function queryBoxInstalledApplication(pkgId: string[]): Promise<AppInfo[]>
export async function queryBoxInstalledApplication(pkgId?: string | string[]): Promise<AppInfo | AppInfo[]> {
  let appidList: string[] = []
  if (!!pkgId) {
    if (Array.isArray(pkgId)) {
      appidList = pkgId
    } else {
      appidList.push(pkgId)
    }
  }
  const rows = (
    await getLzcApiInstnace()!.pkgm.QueryApplication({
      appidList: appidList,
    })
  ).infoList
  if (typeof pkgId == 'string') {
    return rows[0]
  } else {
    return rows
  }
}

/**
 * 安装应用
 * => 在此之前先调用一下 [preInstallApp] 方法
 */
export async function boxInstallApp(repoAppInfo: LazycatAppMetaModel): Promise<boolean> {
  try {
    const prefix = 'https://dl.lazycat.cloud/appstore/lpks'
    // 不能依赖于 `pkgPath`, 因为首页缓存中的内容不准确...
    const { pkgId, pkgHash, version, name } = repoAppInfo
    const downloadUrl = `${prefix}/pkgs/${pkgId}/${pkgId}-v${version}.lpk` //repoAppInfo.pkgPath
    preInstallApp(pkgId, version, pkgHash)
    await getLzcApiInstnace()!.pkgm.Install({
      appid: pkgId,
      url: downloadUrl,
      title: name,
      // sha256: repoAppInfo.pkgHash,
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * 安装应用之前要做的事情
 */
function preInstallApp(appId: string, version: string, appHash: string) {
  bindPkgDownloadCount(appId, version, 'update', appHash)
}

/**
 * <获取 | 更新> 应用下载量
 * => [get] 获取下载量 || [update] 更新下载量
 * => 返回 **-1 | false** 为接口错误边界情况
 */
export async function bindPkgDownloadCount(pkgId: string, version: string, action: 'get' | 'update', hash = ''): Promise<boolean | number> {
  const isUpdate = action === 'update'
  try {
    let data = {}
    let params = {}
    if (isUpdate) {
      const boxId = (await getLzcApiInstnace()!.appinfo).boxId
      data = { pkgId, version, hash, boxId }
    } else {
      params = { pkgId } // , version }
    }
    const resp = await axios.request<{ data: number | null }>({
      url: `/counting`,
      method: isUpdate ? 'POST' : 'GET',
      data,
      params,
      cache: false,
    })
    return isUpdate ? true : resp.data.data ?? -1
  } catch (error) {
    return isUpdate ? false : -1
  }
}

/**
 * 打开应用
 */
export async function openInstalledApp(appID: string, appInfoDomain: string): Promise<void> {
  const _prefixProtocol = window.location.protocol + '//'
  const _fullPath = _prefixProtocol + appInfoDomain
  await AppCommon.LaunchApp(_fullPath, appID)
}

/**
 * 查询系统版本
 */
export async function getSysVersion(): Promise<string> {
  try {
    const res = await getLzcApiInstnace()!.version.Get({})
    return res?.version || ''
  } catch (e) {
    console.error(e)
    return ''
  }
}
