/// <reference types="vite-plugin-pwa/client" />

import axios from 'axios'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { onMounted } from 'vue'

const intervalMS = 30 * 60 * 1000

export default () => {
  const { updateServiceWorker } = useRegisterSW({
    immediate: true,
    onNeedRefresh: () => {
      console.log('[sw] service on need refresh')
    },
    onOfflineReady: () => {
      console.log('[sw] offline ready')
    },
    onRegisterError: (e) => {
      console.log('[sw] on register error', e)
    },
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return

          if ('connection' in navigator && !navigator.onLine) return

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })

          if (resp?.status === 200) await r.update()
        }, intervalMS)
    },
  })

  onMounted(async () => {
    console.warn('recheck', new Date(), import.meta)
    if (import.meta.env.PROD) {
      const gitHash = __GIT_HASH__.trim()
      console.log('current git build hash: ', gitHash)
      try {
        const hash = (await axios.get<string>('/build.txt')).data
        if (hash != gitHash) {
          console.log('app version not match, update service worker')
          await updateServiceWorker()
          console.log('update sw finished')
        }
      } catch (error) {
        console.log('fail to get backend version', error)
      }
    }
  })
}
