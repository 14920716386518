import { DirectiveNamespace } from '../namespace'

import type { App, Directive } from 'vue'

import kAvatar from '@/assets/头像.svg'

function exec($el: HTMLImageElement, path: string | null, init = false) {
  if (init) {
    $el.src = ''
    return
  }
  $el.addEventListener('ionError', () => {
    $el.src = kAvatar
  })
  if (!path) {
    $el.src = kAvatar
  } else {
    $el.src = path
  }
}

const directive = <Directive<HTMLImageElement, string>>{
  beforeMount($el, { value }) {
    exec($el, value)
  },
  beforeUpdate($el, { value }) {
    exec($el, value)
  },
  unmounted($el) {
    exec($el, null, true)
  },
}

export default (vue: App) => {
  vue.directive(DirectiveNamespace.AVATAR, directive)
}
