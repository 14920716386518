import { LazycatAppMetaModel } from '@type/models'

// refer to https://github.com/samstefan/simple-cache/blob/master/index.js
class QueryAppCache {
  expires: number
  map = new Map<string, LazycatAppMetaModel>()
  __timer = new Map<string, any>()
  constructor(exp: number = 24 /* s */) {
    this.expires = exp
  }
  hasValue(appid: string) {
    return !!this.map.has(appid)
  }
  getValue(appid: string) /* must! before use hasValue is check */ {
    return this.map.get(appid)!
  }
  getValues() {
    return this.map
  }
  clear() {
    this.__timer.forEach((item) => {
      item && clearTimeout(item)
    })
    this.__timer.clear()
    this.map.clear()
  }
  addValues(values: LazycatAppMetaModel[]) {
    values.forEach((value) => {
      this.addValue(value.pkgId, value)
    })
  }
  addValue(appid: string, value: LazycatAppMetaModel) {
    const _t = this.__timer.get(appid)
    _t && clearTimeout(_t)
    this.map.set(appid, value)
    let time: any = setTimeout(() => {
      this.map.delete(appid)
      this.__timer.delete(appid)
      time = null
    }, this.expires * 1e3)
    this.__timer.set(appid, time)
  }
}

// ;(window as any).$apps = queryAppCache
export const queryAppCache = new QueryAppCache()
