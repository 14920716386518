export interface LazycatAppArticleModel {
  apps: string[]

  title: string

  subTitle: string

  coverImage?: string
  coverImage_PC: string
  coverImage_Mobile: string
  mobileCover: string
  pcCover: string

  bgFill: string
  detailCover: string

  id: string

  content: string
}

export interface LazycatAppChangelog {
  version: string
  changelog: string
  updateTime: string
}

export type LazycatAppIndex = TopicList[]

export enum LazycatAppTopicType {
  /**
   * 纯应用专题
   */
  text = 1,

  /**
   * 图片专题
   */
  image,
}

export interface TopicList {
  indexNum: number
  type: LazycatAppTopicType
  id: string
  title: string
  appList: LazycatAppMetaModel[]
  imageAppList: LazycatAppArticleModel[]
  data: any[]
  order: number
  isMore: boolean
}

export type ISupportPlatform = 'macos' | 'windows' | 'linux' | 'android' | 'ios'

export interface LazycatAppMetaModel {
  name: string
  pkgId: string
  pkgPath: string
  pkgHash: string
  brief: string
  description: string
  category: string
  price: string

  /**
   * 不支持的平台
   */
  unsupportedPlatforms: ISupportPlatform[]

  /**
   * @deprecated use (supportMobile/supportPC)
   */
  screenshotPaths: string[]

  mobileScreenshotPaths: string[]
  pcScreenshotPaths: string[]
  supportMobile: boolean
  supportPC: boolean
  iconPath: string
  keywords: string
  version: string

  author: string
  /** 来源 */
  source: string

  authorId: string
  updateId: number
  changelog: string
  updateDate: string

  osDependence: string
}

export interface LzycatAppCommentModel {
  commentId: number
  appId: string

  /**
   * 用户id
   */
  userid: number

  /**
   * 昵称
   */
  nickname: string

  /**
   * 头像
   */
  avatar: string

  /**
   * 评分
   */
  score: number

  /**
   * 评论内容
   */
  content: string

  /**
   * 是否已点赞
   */
  liked: number

  /**
   * 创建时间
   */
  createdAt: Date

  /**
   * 更新时间
   */
  updatedAt: Date

  /**
   * 点赞数
   */
  likeCounts: number
}
export interface LazycatAppScoreModel {
  total: number
  score: number
  score_table: number[]
}

export interface LazycatAppCategories {
  name: string
  icon: string
}

export interface Page<T> {
  items: T
  page: number
  size: number
  max_page: number
  total_pages: number
  total: number
  last: boolean
  first: boolean
  visible: number
}

// Build: not set
/* Do not change, this code is generated from Golang structs */

export interface User {
  id: number
  username: string
  nickname: string
  avatar: string
  description: string
  status: number
  githubUsername: string
  /**
   *
   * 是否当前登录的用户
   */
  isCurrentLoginUser: boolean
}
export interface PageParameter<A> {
  /**
   *
   * 分页的页数
   */
  page: number
  /**
   *
   * 分页的大小
   */
  size: number
  /**
   *
   * 根据结构中的字段进行排序
   */
  sort: string
  /**
   *
   * 根据结构中的字段自定义的过滤条件
   */
  filters: A
  /**
   *
   * 搜索关键词字段
   */
  keyword: string
  /**
   *
   * 附加字段
   */
  [key: string]: any
}
export interface GuideLine {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 内容
   */
  content: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 短视频类型下需设置视频地址
   */
  shorts?: string
  /**
   *
   * 图片地址
   */
  images: string[]
  /**
   *
   * 视频连接地址
   */
  videos: string[]
  /**
   *
   * 商品连接地址
   */
  products: string[]
}
export interface DataCreateResponse {
  id: number
}
export interface SnapshotResponse {
  url: string
}
export interface GuidelineResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 内容
   */
  content: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 短视频类型下需设置视频地址
   */
  shorts?: string
  /**
   *
   * 图片地址
   */
  images: string[]
  /**
   *
   * 视频连接地址
   */
  videos: string[]
  /**
   *
   * 商品连接地址
   */
  products: string[]
  /**
   *
   * 阅读数
   */
  views: number
  /**
   *
   * 点赞数，只有当前攻略的点赞数
   */
  thumbCount: number
  /**
   *
   * 是否处于点赞状态
   */
  thumbed: boolean
  /**
   *
   * 评论数，当前攻略的评论以及评论的评论
   */
  commentCount: number
  /**
   *
   * 是否关注当前up主
   */
  followed: boolean
  /**
   *
   * 攻略的分类
   */
  categories: string[]
  /**
   *
   * 攻略的分类ID
   */
  categoryIds: number[]
}
export interface GuidelineCardResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 阅读数
   */
  views: number
  /**
   *
   * 点赞数，只有当前攻略的点赞数
   */
  thumbCount: number
  /**
   *
   * 是否处于点赞状态
   */
  thumbed: boolean
  /**
   *
   * 攻略的分类
   */
  categories: string[]
}
export interface CommentRequest {
  /**
   *
   * 爷爷级节点id
   */
  originId: number
  /**
   *
   * (父级节点id)回复的id
   */
  destinationId: number
  /**
   *
   * (父级节点)类型: 攻略 评论
   */
  type: string
  /**
   *
   * 评论的内容
   */
  content: string
}
export interface CommentResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 爷爷级节点id
   */
  originId: number
  /**
   *
   * (父级节点id)回复的id
   */
  destinationId: number
  /**
   *
   * (父级节点)类型: 攻略 评论
   */
  type: string
  /**
   *
   * 评论的内容
   */
  content: string
  /**
   *
   * 评论所属的攻略id
   */
  guidelineId: number
  /**
   *
   * 被回复的用户的信息
   */
  destinationUser?: User
  /**
   *
   * 点赞数
   */
  thumbCount: number
  /**
   *
   * 是否处于点赞状态
   */
  thumbed: boolean
  /**
   *
   * 子评论分页
   */
  childrens?: Page<CommentResponse[]>
}
export interface ThumbRequest {
  /**
   *
   * 被点赞的评论或者攻略的id
   */
  destinationId: number
  /**
   *
   * 来源类型: 攻略，评论
   */
  type: string
}
export interface ThumbResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 被点赞的评论或者攻略的id
   */
  destinationId: number
  /**
   *
   * 来源类型: 攻略，评论
   */
  type: string
}
export interface GuidelineCategoryRequest {
  /**
   *
   * 分类名称
   */
  name: string
}
export interface GuidelineCategoryResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 分类名称
   */
  name: string
  /**
   *
   * 分类索引
   */
  index: number
}
export interface DraftRequest {
  id?: number
  guidelineId?: number
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 内容
   */
  content: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 短视频类型下需设置视频地址
   */
  shorts?: string
  /**
   *
   * 攻略的分类ID
   */
  categoryIds: number[]
  status?: string
  reason?: string
}
export interface DraftResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  guidelineId?: number
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 内容
   */
  content: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 短视频类型下需设置视频地址
   */
  shorts?: string
  /**
   *
   * 图片地址
   */
  images: string[]
  /**
   *
   * 视频连接地址
   */
  videos: string[]
  /**
   *
   * 商品连接地址
   */
  products: string[]
  status: string
  reason: string
  /**
   *
   * 攻略的分类ID
   */
  categoryIds: number[]
}
export interface PloyRequest {
  /**
   *
   * 活动的备注名
   */
  name: string
  /**
   *
   * 活动的封面图片连接
   */
  cover: string
  /**
   *
   * 活动的封面图片连接（pc端）
   */
  coverPc: string
  /**
   *
   * 活动跳转的连接
   */
  link: string
}
export interface PloyResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 活动的备注名
   */
  name: string
  /**
   *
   * 活动的封面图片连接
   */
  cover: string
  /**
   *
   * 活动的封面图片连接（pc端）
   */
  coverPc: string
  /**
   *
   * 活动跳转的连接
   */
  link: string
  /**
   *
   * 是否展示这个活动
   */
  show: boolean
  /**
   *
   * 活动索引
   */
  index: number
}
export interface FollowRequest {
  /**
   *
   * 想关注的uid
   */
  followUid: number
}
export interface FollowResponse {
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 表示当前登录的用户是否在这个用户的粉丝列表中
   */
  fans: boolean
  /**
   *
   * 表示当前登录的用户是否在这个用户的关注列表中
   */
  followed: boolean
  /**
   *
   * 表示这一个用户的攻略总数
   */
  guidelineCount: number
  /**
   *
   * 表示这一个用户的粉丝数
   */
  fansCount: number
}
export interface FansResponse {
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 表示当前登录的用户是否在这个用户的粉丝列表中
   */
  fans: boolean
  /**
   *
   * 表示当前登录的用户是否在这个用户的关注列表中
   */
  followed: boolean
  /**
   *
   * 表示这一个用户的攻略总数
   */
  guidelineCount: number
  /**
   *
   * 表示这一个用户的粉丝数
   */
  fansCount: number
}
export interface UserInfoResponse {
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 总获赞数，包括评论和攻略
   */
  receiveThumbs: number
  /**
   *
   * 关注数
   */
  follows: number
  /**
   *
   * 粉丝
   */
  fans: number
  /**
   *
   * 已关注
   */
  followed: boolean
  /**
   *
   * 隐藏我的关注列表
   */
  hideFollows: boolean
  /**
   *
   * 隐藏我的粉丝列表
   */
  hideFans: boolean
  /**
   *
   * 隐藏我的赞
   */
  hideThumbs: boolean
  /**
   *
   * 只允许我评论的人@我
   */
  onlyCommentedAtMe: boolean
  /**
   *
   * 只允许我关注的人评论我
   */
  onlyFollowedCommentMe: boolean
}
export interface FeedResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 内容
   */
  content: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 短视频类型下需设置视频地址
   */
  shorts?: string
  /**
   *
   * 图片地址
   */
  images: string[]
  /**
   *
   * 视频连接地址
   */
  videos: string[]
  /**
   *
   * 商品连接地址
   */
  products: string[]
  /**
   *
   * 阅读数
   */
  views: number
  /**
   *
   * 点赞数，只有当前攻略的点赞数
   */
  thumbCount: number
  /**
   *
   * 是否处于点赞状态
   */
  thumbed: boolean
  /**
   *
   * 评论数，当前攻略的评论以及评论的评论
   */
  commentCount: number
  /**
   *
   * 是否关注当前up主
   */
  followed: boolean
  /**
   *
   * 攻略的分类
   */
  categories: string[]
  /**
   *
   * 攻略的分类ID
   */
  categoryIds: number[]
}
export interface UserApplicationResponse {
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 商品连接地址
   */
  products: string[]
}
export interface UserThumbGuidelineResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 标题
   */
  title: string
  /**
   *
   * 类型
   */
  type: string
  /**
   *
   * 封面
   */
  cover?: string
  /**
   *
   * 阅读数
   */
  views: number
  /**
   *
   * 点赞数，只有当前攻略的点赞数
   */
  thumbCount: number
  /**
   *
   * 是否处于点赞状态
   */
  thumbed: boolean
  /**
   *
   * 攻略的分类
   */
  categories: string[]
}
export interface UserSearchResponse {
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 表示当前登录的用户是否在这个用户的粉丝列表中
   */
  fans: boolean
  /**
   *
   * 表示当前登录的用户是否在这个用户的关注列表中
   */
  followed: boolean
  /**
   *
   * 表示这一个用户的攻略总数
   */
  guidelineCount: number
  /**
   *
   * 表示这一个用户的粉丝数
   */
  fansCount: number
}
export interface ReceiveThumbedResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 被点赞的评论或者攻略的id
   */
  destinationId: number
  /**
   *
   * 来源类型: 攻略，评论
   */
  type: string
  /**
   *
   * 封面
   */
  cover: string
  /**
   *
   * 评论所属的攻略id
   */
  guidelineId: number
}
export interface GithubTopLangDataItem {
  /**
   *
   * 语言名称
   */
  name: string
  /**
   *
   * 语言颜色
   */
  color: string
  /**
   *
   * 统计数量
   */
  size: number
}
export interface GithubRank {
  /**
   *
   * 等级
   */
  level: string
  /**
   *
   * 得分
   */
  score: number
}
export interface GithubStatsData {
  /**
   *
   * 用户名
   */
  name: string
  /**
   *
   * PR 总数
   */
  totalPRs: number
  /**
   *
   * Commits 总数
   */
  totalCommits: number
  /**
   *
   * Issues 总数
   */
  totalIssues: number
  /**
   *
   * 贡献数
   */
  contributedTo: number
  /**
   *
   * 排名
   */
  rank: GithubRank
}
export interface GithubInfoResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 使用最多的语言
   */
  topLangs: { [key: string]: GithubTopLangDataItem }
  /**
   *
   * 其他统计信息
   */
  summary: GithubStatsData
}
export interface PrivacySettingRequest {
  /**
   *
   * 隐藏我的关注列表
   */
  hideFollows: boolean
  /**
   *
   * 隐藏我的粉丝列表
   */
  hideFans: boolean
  /**
   *
   * 隐藏我的赞
   */
  hideThumbs: boolean
  /**
   *
   * 只允许我评论的人@我
   */
  onlyCommentedAtMe: boolean
  /**
   *
   * 只允许我关注的人评论我
   */
  onlyFollowedCommentMe: boolean
}
export interface PrivacySettingResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 隐藏我的关注列表
   */
  hideFollows: boolean
  /**
   *
   * 隐藏我的粉丝列表
   */
  hideFans: boolean
  /**
   *
   * 隐藏我的赞
   */
  hideThumbs: boolean
  /**
   *
   * 只允许我评论的人@我
   */
  onlyCommentedAtMe: boolean
  /**
   *
   * 只允许我关注的人评论我
   */
  onlyFollowedCommentMe: boolean
}
export interface RepliesResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: User
  /**
   *
   * 爷爷级节点id
   */
  originId: number
  /**
   *
   * (父级节点id)回复的id
   */
  destinationId: number
  /**
   *
   * (父级节点)类型: 攻略 评论
   */
  type: string
  /**
   *
   * 评论的内容
   */
  content: string
  /**
   *
   * 评论所属的攻略id
   */
  guidelineId: number
  /**
   *
   * 如果类型是攻略，则返回标题；如果类型是评论，则返回评论内容
   */
  summary: string
  /**
   *
   * 攻略封面
   */
  cover: string
}
export interface MessageUnreadResponse {
  /**
   *
   * @我的
   */
  atMe: number
  /**
   *
   * 获赞
   */
  thumbs: number
  /**
   *
   * 回复
   */
  replies: number
  /**
   *
   * 粉丝
   */
  fans: number
}

export interface AppInfo {
  name: string
  pkgId: string
  description: string
  category: string[]
  version: string
  author: string
  authorId: number
  iconUrl: string
}

export interface TabItem {
  label: string
  name: string
}

export interface LazycatAppMetaModel {
  name: string
  pkgId: string
  pkgPath: string
  pkgHash: string
  description: string
  category: string
  price: string

  /**
   * 不支持的平台
   */
  unsupportedPlatforms: ISupportPlatform[]

  /**
   * @deprecated use (supportMobile/supportPC)
   */
  screenshotPaths: string[]

  mobileScreenshotPaths: string[]
  pcScreenshotPaths: string[]
  supportMobile: boolean
  supportPC: boolean
  iconPath: string
  keywords: string
  version: string

  author: string
  /** 来源 */
  source: string

  authorId: string
  updateId: number
  changelog: string
  updateDate: string
}

export interface ISafeRun {
  /**
   * 私有盒子调用(不在公网域名下调用)
   */
  privateApi: boolean

  /**
   * 是否为安卓
   */
  isAndroid: boolean

  /**
   * 是否为ios
   */
  isIos: boolean
}
