import { default as _axios } from 'axios'

import { RES_PREFIX } from '@/config'
import { LazycatAppArticleModel, LazycatAppChangelog, LazycatAppIndex, LazycatAppMetaModel, LazycatAppTopicType, TopicList } from '@/typing/models'
import type { QueryAndPaginationOptional, ResponseAsPromise } from '@type/models'
import { BaseResponse, LazycatAppCategories, LazycatAppScoreModel, LzycatAppCommentModel, PaginationPageAndLimit, SearchResponseType } from '@type/models'
import { axios as apiInstance } from './axios'
import { queryAppCache } from './cache'

const formHeader = {
  'Content-Type': 'multipart/form-data',
}

const metarepoInstance = _axios.create({
  baseURL: 'https://dl.lazycat.cloud/appstore/metarepo', // '/metarepo',
})

/**
 * 搜索应用
 */
export async function search(keyword: string): Promise<SearchResponseType> {
  const resp = await apiInstance.get<{ data: SearchResponseType }>(`/search?keyword=${keyword}`)
  const data = resp.data.data
  return data
}

/**
 * 获取应用分类列表
 */
export async function getCategories() {
  try {
    const resp = await apiInstance.get<BaseResponse<LazycatAppCategories[]>>('/app/categories')
    return resp.data.data
  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * 获取专题纯应用列表
 */
export async function getTopicAppList(topicId: string): Promise<LazycatAppMetaModel[]> {
  const resp = await metarepoInstance.get<{ data: string[] }>(`/topic/${topicId}/appList.json`)
  const ids = resp.data.data
  const data = await queryRepoAppInfo(ids)
  return data
}

/**
 * 获取应用列表
 */
export async function getAppList(
  data: QueryAndPaginationOptional<{
    categories: string
  }>
): ResponseAsPromise<LazycatAppMetaModel[]> {
  try {
    const router = '/app/list'
    const resp = await apiInstance.get(router, {
      params: data,
    })
    return resp.data as BaseResponse<LazycatAppMetaModel[]>
  } catch (err) {
    console.error(err)
    return new Error(err as any)
  }
}

function trimChangelog<T extends { changelog: string }>(item: T): T {
  if (item.changelog) {
    item.changelog = item.changelog.trim()
  }
  return item
}

/**
 * 获取商店内的某个 app 的详情信息
 */
export async function queryRepoAppInfo(appId: string): Promise<LazycatAppMetaModel>
export async function queryRepoAppInfo(appId: string[]): Promise<LazycatAppMetaModel[]>
export async function queryRepoAppInfo(appId: string | string[]): Promise<LazycatAppMetaModel | LazycatAppMetaModel[]> {
  const fetchMany = Array.isArray(appId)
  const pkgIds: string[] = fetchMany ? appId : [appId]
  const realFetchIds = [...pkgIds]
  const cacheValue = new Map<string, LazycatAppMetaModel>()
  pkgIds.forEach((value, idx) => {
    if (queryAppCache.hasValue(value)) {
      cacheValue.set(value, queryAppCache.getValue(value))
      realFetchIds.splice(idx, 1)
    }
  })
  if (!realFetchIds.length) {
    const result = Array.from(cacheValue.values())
    if (fetchMany) return result
    return result[0]
  }
  const resp = await apiInstance.request<{ data: LazycatAppMetaModel[] }>({
    url: '/app/info',
    method: 'POST',
    data: {
      pkgIds: realFetchIds,
    },
  })
  let apps = resp.data.data.map(trimChangelog)
  if (!fetchMany) return apps[0]
  queryAppCache.addValues(apps)
  const appendValues = Array.from(cacheValue.values())
  apps = apps.concat(appendValues)
  return apps
}

/**
 * 获取应用评分信息
 */
export async function getAppScoreInfo(pkgId: string) {
  const resp = await apiInstance.get<BaseResponse<LazycatAppScoreModel>>(`/comment/score/${pkgId}`, {
    cache: false,
  })
  return resp.data.data
}

/**
 * 查看历史记录
 */
export async function getAppChangelog(pkgId: string): Promise<LazycatAppChangelog[]> {
  const resp = await metarepoInstance.get<LazycatAppChangelog[]>(`/apps/${pkgId}/changelog.json`)
  return resp.data.map(trimChangelog)
}

/**
 * 获取应用评论
 */
export async function getCommentListAppId(appId: string, option?: Partial<PaginationPageAndLimit>): Promise<Error | [LzycatAppCommentModel[], boolean]> {
  const mergeOption = {
    ...{
      page: 1,
      limit: 10,
    },
    ...(option || {}),
  }
  try {
    const router = '/comment/list/' + appId
    const resp = await apiInstance.request<BaseResponse<{ list: LzycatAppCommentModel[]; isNext: boolean }>>({
      url: router,
      method: 'GET',
      params: mergeOption,
      cache: false,
    })
    const data = resp.data.data
    return [data.list, data.isNext]
  } catch (err) {
    console.error(err)
    return new Error(err as any)
  }
}

/**
 * 发布一条评论
 */
export async function postCommentAppId(appId: string, comment: string, score: number) {
  try {
    const url = '/comment/' + appId
    const resp = await apiInstance.request({
      url,
      method: 'POST',
      headers: formHeader,
      data: { content: comment, score },
      cache: false,
    })
    return resp.data
  } catch (err) {
    console.error(err)
    return err
  }
}

/**
 * 给某个app下的某条评论点赞
 */
export async function postCommentLikeCommentId(pkgId: string, commentId: number, eff: boolean): Promise<string> {
  try {
    const url = `/comment/${eff ? 'un' : ''}like`
    const resp = await apiInstance.request<BaseResponse<any>>({
      method: 'POST',
      url,
      data: {
        appId: pkgId,
        commentId,
      },
      headers: formHeader,
      cache: false,
    })
    const data = resp.data
    const success = data.success
    if (!success) {
      const { message } = data
      return message
    }
    return ''
  } catch (err) {
    return '' + err
  }
}

// 为你写诗...................
//
// 你看那
// 一朵朵
//
// 菊花爆满山

const replaceImage = (raw: string) => raw.replace(/^\/public\//, '')
const replaceImage1 = (raw: string) => raw.replace('/public/', '/screenshots/')
const refixImage20230912 = (item: any) => {
  if (item.pcCover) {
    item.coverImage_PC = replaceImage(item.coverImage_PC || '')
    item.coverImage = replaceImage(item.pcCover || '')
    item.pcCover = replaceImage(item.pcCover || '')
  }
  if (item.mobileCover) {
    item.coverImage_Mobile = replaceImage(item.coverImage_Mobile || '')
    item.mobileCover = replaceImage(item.mobileCover || '')
  }
  ;['coverImage', 'coverImage_PC', 'mobileCover', 'pcCover'].forEach((key) => {
    if (!item[key]) return
    item[key] = RES_PREFIX + '/uploads/' + item[key]
  })
  return item
}
const refixImage20231110 = (item: LazycatAppMetaModel) => {
  item.iconPath = 'icon.png'
  item.pcScreenshotPaths = item.pcScreenshotPaths.map(replaceImage1)
  item.mobileScreenshotPaths = item.mobileScreenshotPaths.map(replaceImage1)
  return item
}

export function getIndexWithCache() {
  const $indexData = localStorage.getItem('$index')
  if (!$indexData) return null
  const data = JSON.parse($indexData) as {
    index: string
    data: LazycatAppIndex
  }
  return data
}

function setIndexCache(index: string, data: LazycatAppIndex) {
  if (!data || !index) return
  localStorage.setItem('$index', JSON.stringify({ index, data }))
}

async function getRecent(maxCount: number): Promise<TopicList> {
  try {
    const resp = await apiInstance.get(`/app/recent?count=${maxCount}`)
    return {
      data: resp.data.data,
      id: '100',
      type: LazycatAppTopicType.text,
      title: '最近更新',
      appList: resp.data.data.map(refixImage20230912),
      order: 3,
      indexNum: maxCount,
      isMore: false,
      imageAppList: [],
    } as TopicList
  } catch (e) {
    console.error(e)
    return {
      data: [],
      id: '100',
      type: LazycatAppTopicType.text,
      title: '最近更新',
      appList: [],
      order: 3,
      indexNum: maxCount,
      isMore: false,
      imageAppList: [],
    } as TopicList
  }
}

export async function getRemoteTag() {
  const remoteTag = (await metarepoInstance.get<string>(`/op/index`)).data
  return remoteTag
}

/**
 * 获取首页数据
 */
export async function getIndex(tag = '', useCache: boolean, saveCache = false): Promise<LazycatAppIndex> {
  const findRecent = function (data: LazycatAppIndex): number {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title == '最近更新') {
        return i
      }
    }
    return -1
  }
  const cache = getIndexWithCache()

  // 使用缓存并且有缓存
  // 缓存的index和要请求的tag相同
  if (useCache && cache && cache.index == tag) {
    const data = cache.data
    const recentUpdateIndex = findRecent(data)
    if (recentUpdateIndex > 0) {
      const recent = await getRecent(data[recentUpdateIndex].indexNum)
      data.splice(recentUpdateIndex, 1, recent)
    }
    data.forEach((item) => {
      if (item.type == LazycatAppTopicType.text) {
        queryAppCache.addValues(item.data.map(refixImage20231110))
      }
    })
    return data
  }

  // 如果tag不存在,获取最新的
  if (!tag) {
    tag = (await metarepoInstance.get<string>(`/op/index`)).data
  }
  const bannerData = await metarepoInstance.get(`/op/${tag}/banner.json`)
  const resp = await metarepoInstance.get<LazycatAppIndex>(`/op/${tag}/mobile.json`)
  const fill = <TopicList>{
    type: LazycatAppTopicType.image,
    imageAppList: bannerData.data.map(refixImage20230912),
  }
  const data = resp.data.map((item) => {
    const data = item.data.map(refixImage20230912)
    if (item.type == LazycatAppTopicType.text) {
      queryAppCache.addValues(item.data.map(refixImage20231110))
      item.appList = data
    } else {
      item.imageAppList = data
    }
    return item
  })
  const result = [fill, ...data]
  if (saveCache) setIndexCache(tag, result)

  const recentUpdateIndex = findRecent(result)
  if (recentUpdateIndex > 0) {
    const recent = await getRecent(result[recentUpdateIndex].indexNum)
    result.splice(recentUpdateIndex, 1, recent)
  }

  return result
}

export async function getArticleDetail(id: string) {
  const data = (await metarepoInstance.get(`/article/${id}.json`)).data as LazycatAppArticleModel
  data['coverImage'] = `${RES_PREFIX}/uploads/${data['pcCover']}`
  return data
}
