<template>
  <div class="toast-box">
    <div class="toast-wrapper">
      <span class="toast-text" :style="{ color: color }">
        {{ value }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Toast',
  props: {
    value: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 3000,
    },
    background: {
      type: String,
      default: '#000',
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
})
</script>

<style scoped>
.toast-box {
  z-index: 99999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.toast-wrapper {
  max-width: 88vw;
  height: 44px;
  border-radius: 8px;
  opacity: 0.8;
  display: flex;
  justify-items: center;
  align-items: center;
  background: rgb(8, 8, 8);
  padding: 8px 10px;
}
.toast-text {
  font-size: 14px;
}
</style>
