import { isClient, isElectron, isMobile, isWujie, safeRun } from '@/utils/env'
import { setControlViewVisibility } from '@/utils/internal_lzc_app_ext'
import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import { nextTick } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import mobileRoutes from './mobile'
import pcRoutes from './pc'

import { useAccessControler, usePermission } from '@/store'
import { useThrottleFn } from '@vueuse/core'

import { SimpleToast } from '@comp/Toast'

// vue-router hook can't use pinia
// link https://pinia.vuejs.org/core-concepts/outside-component-usage.html
// maybe it work? https://github.com/vuejs/pinia/discussions/723#discussioncomment-2110660
const updateInstallStatusWithDebounce = useThrottleFn(async () => {
  const per = usePermission()
  const status = await per.updateInstallableStatusWithSDK()
  if (!status) {
    useAccessControler().update()
  }
}, 420)

const outputRoutes = (function () {
  if (isElectron() || isWujie()) return pcRoutes
  if (isClient()) return mobileRoutes
  return isMobile() ? mobileRoutes : pcRoutes
})()

export const renderIonRouter = (function () {
  const meta = outputRoutes[1].meta
  return !(meta && meta.desc == 'PC')
})()

class RestorePostion {
  #map = new Map<string, { page: string; el: string; top: number; after: number }>()
  constructor(maps: { page: string; el: string; after: number }[]) {
    for (const map of maps) {
      this.#map.set(map.page, { ...map, top: 0 })
    }
  }

  #isPC(target: { meta: Record<string, any> }) {
    return target.meta.desc == 'PC'
  }

  hijack(_: RouteLocationNormalized, from: RouteLocationNormalized) {
    for (const [page] of this.#map) {
      if (from.name == page && this.#isPC(from)) {
        const $el = document.querySelector(this.#map.get(page)!.el)
        if (!$el) return
        const { scrollTop: top } = $el
        this.#map.get(page)!.top = top
        return
      }
    }
  }

  async restore(to: RouteLocationNormalized, _: RouteLocationNormalized) {
    for (const [, value] of this.#map) {
      if (to.name == value.page && this.#isPC(to)) {
        if (!value.top) return
        const $el = document.querySelector(value.el)
        if (!$el) return
        await new Promise((res) => {
          setTimeout(() => {
            if (!value.top) return
            $el.scrollTo({
              top: value.top,
              // behavior: 'smooth',
            })
            value.top = 0
            res(true)
          }, value.after)
        })
        return
      }
    }
  }
}

const restorePostion = new RestorePostion([
  {
    page: 'shop-recommend',
    el: '.pc-layout',
    after: 210,
  },
  {
    page: 'shop-category',
    el: '.pc-category-layout',
    after: 240,
  },
  {
    page: 'search',
    el: '.search-layout',
    after: 240,
  },
])

const router = createRouter({
  history: createWebHashHistory('/'),
  routes: outputRoutes,
  // refer:
  // - https://github.com/dcloudio/uni-app/blob/273263d514267c1091d5477c3614bb7e0b7dad01/src/core/service/plugins/index.js#L100
  // - https://github.com/AnatolyRugalev/photoprism/blob/b6f312e577ec54e94aab34e7f7563faaa543c2d4/frontend/src/app.js#L166
  async scrollBehavior(to, from, _) {
    restorePostion.restore(to, from)
  },
})

router.onError(async (error) => {
  console.error(error)
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    SimpleToast(
      {
        value: '资源加载失败',
        duration: 1200,
        background: '#000',
        color: '#fff',
        single: true,
      },
      () => {
        location.href = location.origin
      }
    )
  }
})

function dynamicChangeTitle(to: RouteLocationNormalized) {
  const { meta } = to
  const { title } = meta as Record<string, string>
  if (!!title) {
    document.title = title
  }
}

async function routerBottomBarBeforeHook(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  await nextTick()
  const appstoreInner = from.fullPath.startsWith('/shop') || from.fullPath.includes('search')
  const isIndexPage = to.name == 'shop-home'
  const isFromUserInfo = isIndexPage && to.query.disable === 'true'
  if (isFromUserInfo && !appstoreInner) return
  await setControlViewVisibility(isIndexPage)
}

function checkInstallBeforeHook(_: RouteLocationNormalized) {
  updateInstallStatusWithDebounce()
}

router.beforeEach((to, from, next) => {
  restorePostion.hijack(to, from)
  dynamicChangeTitle(to)
  safeRun(
    async () => {
      try {
        await routerBottomBarBeforeHook(to, from)
      } catch (error) {
        console.error(error)
      }
    },
    {
      privateApi: false,
      isAndroid: true,
    }
  )
  safeRun(() => {
    try {
      checkInstallBeforeHook(to)
    } catch (error) {
      console.error(error)
    }
  })
  next()
})

export default router
