<template>
  <div :style="style">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { computed } from 'vue'

const props = defineProps({
  /**
   * 填充大小
   */
  padding: {
    type: Number,
    required: false,
    default: 16,
  },
  /**
   * 使用 `padding`
   * => 默认使用 `margin`
   */
  isPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const style = computed<CSSProperties>(() => {
  const _size = props.padding + 'px'
  let obj = <CSSProperties>{
    marginLeft: _size,
    marginRight: _size,
  }
  if (props.isPadding) {
    obj = {
      paddingLeft: _size,
      paddingRight: _size,
    }
  }
  return obj
})
</script>
