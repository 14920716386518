import { App, Directive } from 'vue'
import { DirectiveNamespace } from '../namespace'

// => 参考以下链接
// -> https://www.3dwindy.com/article/118012
// -> https://icode.best/i/13949141604281

const Time = {
  //获取当前时间戳
  getUnix: function () {
    const date = new Date()
    return date.getTime()
  },
  //获取今天0点0分0秒的时间戳
  getTodayUnix: function () {
    const date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date.getTime()
  },
  //获取今年1月1日0点0分0秒的时间戳
  getYearUnix: function () {
    const date = new Date()
    date.setMonth(0)
    date.setDate(1)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date.getTime()
  },
  //获取标准年月日
  getLastDate: function (time: number) {
    const date = new Date(time)
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const result = date.getFullYear() + '-' + month + '-' + day
    return result
  },
  //转换时间
  getFormatTime: function (timestamp: number) {
    const now = this.getUnix() // 当前时间戳
    const today = this.getTodayUnix() // 今天0点的时间戳
    const timer = (now - timestamp) / 1000 // 转换为秒级时间戳
    let tip = ''

    if (timer <= 0) {
      tip = '刚刚'
    } else if (Math.floor(timer / 60) <= 0) {
      tip = '刚刚'
    } else if (timer < 3600) {
      tip = Math.floor(timer / 60) + '分钟前'
    } else if (timer >= 3600 && timestamp - today >= 0) {
      tip = Math.floor(timer / 3600) + '小时前'
    } else if (timer / 86400 <= 31) {
      tip = Math.ceil(timer / 86400) + '天前'
    } else {
      tip = this.getLastDate(timestamp)
    }
    return tip
  },
}

type MyElement = HTMLElement & {
  /**
   * 定时器
   */
  __timeout__: any
}

// refresh

const REFRESH_TIME = 60000

const directive = <Directive<MyElement, string>>{
  mounted(el, { value }) {
    const timeCtx = Date.parse(value)
    el.innerHTML = Time.getFormatTime(timeCtx)
    el.__timeout__ = setInterval(function () {
      el.innerHTML = Time.getFormatTime(timeCtx)
    }, REFRESH_TIME)
  },
  unmounted(el) {
    clearInterval(el.__timeout__)
    delete el.__timeout__
  },
}

export default (vue: App) => {
  vue.directive(DirectiveNamespace.TIME, directive)
}
