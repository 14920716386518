import { onMounted, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default (appName: string, isDebug = true) => {
  const route = useRoute()
  const router = useRouter()

  watch(
    route,
    (newValue, _oldValue) => {
      if (window.__POWERED_BY_WUJIE__) {
        if (isDebug) console.log('[sub] route change', newValue.fullPath)
        window.$wujie.bus.$emit('sub-router-change', { name: appName, path: newValue.fullPath })
      }
    },
    { immediate: true }
  )

  function changeRouter(data: { name: string; path: string }) {
    let { name, path } = data
    if (isDebug) console.log('[sub] receive main router change', name, path)
    if (name == appName) {
      if (!path) {
        path = '/'
      }
      router.push(path)
      return
    }
  }
  onMounted(() => {
    if (window.__POWERED_BY_WUJIE__) {
      window.$wujie.bus.$on('main-router-change', changeRouter)
    }
  })

  onUnmounted(() => {
    if (window.__POWERED_BY_WUJIE__) {
      window.$wujie.bus.$off('main-router-change', changeRouter)
    }
  })
}
