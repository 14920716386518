// The lib copy by https://github.com/ryancole/chunk
export function chunk<T>(collection: Array<T>, size: number): T[][] {
  var result: any[][] = []

  // default size to two item
  size = size || 2

  // add each chunk to the result
  for (var x = 0; x < Math.ceil(collection.length / size); x++) {
    var start = x * size
    var end = start + size
    const _ = collection.slice(start, end)
    result.push(_)
  }

  return result
}
