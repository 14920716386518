export const enum RouterGoListActionType {
  /**
   * 纯应用专题
   */
  topicList = 1,

  /**
   * 分类
   */
  categoryList,
}
