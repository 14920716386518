import { checkAllowInstallApp } from '@/api'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePermission = defineStore('permission', () => {
  const installable = ref(false)

  const setInstallable = (newVal: boolean) => {
    installable.value = newVal
  }

  async function updateInstallableStatusWithSDK() {
    const status = await checkAllowInstallApp()
    setInstallable(status)
    return status
  }

  return {
    installable,
    updateInstallableStatusWithSDK,
    setInstallable,
  }
})
