// 参考文献 => https://juejin.cn/post/7141697183597723679

import { createVNode, render } from 'vue'

import ToastTemplate from './toast.vue'

interface ResultParams {
  destory: () => void
}

export interface IProps {
  value: string
  duration: number
  background: string
  color: string
  single: boolean
}

const $$el = '__simple_toast'

const defaultOpt: Partial<IProps> = {
  duration: 1500,
  single: false,
}

export const SimpleToast = (options: Partial<IProps>, cb?: () => void): ResultParams | false => {
  if (document.querySelector(`.${$$el}`) && options.single) return false
  const container = document.createElement('div')
  container.classList.add($$el)
  const opt = { ...defaultOpt, ...options }
  const vm = createVNode(ToastTemplate, opt)
  render(vm, container)
  document.body.appendChild(container)
  setTimeout(() => {
    destory()
    cb && cb()
  }, opt.duration)

  const destory = () => {
    container && container.remove()
  }
  return { destory }
}
