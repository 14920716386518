import router from '@/router'
import { LzcApp } from '@lazycatcloud/sdk/dist/extentions'
import { RouteLocationRaw } from 'vue-router'

interface ISafeRun {
  /**
   * 私有盒子调用(不在公网域名下调用)
   */
  privateApi: boolean

  /**
   * 是否为安卓
   */
  isAndroid: boolean

  /**
   * 是否为ios
   */
  isIos: boolean
}

export const isDev = import.meta.env.DEV

export function isWujie() {
  return window.__POWERED_BY_WUJIE__
}

export function isClient(): boolean {
  return !!navigator.userAgent.match(/(Lazycat_101|Lazycat_ContentView|Lazycat_Client|lzc-client)/i)
}

export function isIOS() {
  return LzcApp.isIosWebShell()
}

export function isElectron() {
  // @ts-ignore
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true
  }

  // @ts-ignore
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true
  }

  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
    return true
  }

  return false
}

/**
 * 检测当前域名是否为公网环境
 */
export function checkIsPublicDomain(): boolean {
  let href = window.location.href
  if (window.__POWERED_BY_WUJIE__) {
    href = window.$wujie.location!.href
  }

  const publicHosts = ['lazycat.cloud', 'lazycatmicroserver.com']
  for (let host of publicHosts) {
    if (href.includes(host)) {
      return true
    }
  }
  return false
}

export const $$devSiteMode = (import.meta.env.MODE == 'site' || window.__POWERED_BY_WUJIE__) ?? false

export function safeRun(fn: Function, option: Partial<ISafeRun> = { privateApi: true }) {
  let { privateApi, isAndroid: a, isIos: i } = option
  if (a && !LzcApp.isAndroidWebShell()) return
  if (i && !LzcApp.isIosWebShell()) return
  if (privateApi && checkIsPublicDomain()) return
  !!fn && fn()
}

export function safeOpen(ctx: RouteLocationRaw, kNamespace = 'appstore') {
  const path = router.resolve(ctx)
  if ($$devSiteMode && window.$wujie) {
    window.$wujie.props.open(kNamespace, path.fullPath)
    return
  }
  router.push(path)
}

export function isMobile(): boolean {
  return !!navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}
