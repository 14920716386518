import { queryAppAccessPolicy } from '@/api'
import { AppAccessPolicy } from '@lazycatcloud/sdk/dist/sys/ingress'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAccessControler = defineStore('accessControler', () => {
  const table = ref<AppAccessPolicy>({
    noLimit: false,
    allowAccessAppids: [],
  })

  async function update() {
    const data = await queryAppAccessPolicy()
    if (!data) return
    table.value = data
  }

  function visibleable(appid: string): boolean {
    if (!!table.value.noLimit) return true
    return table.value.allowAccessAppids.includes(appid)
  }

  update()

  return {
    table,
    update,
    visibleable,
  }
})
