export class DirectiveNamespace {
  /**
   * 动态拼接`app`图标的`url`
   *
   * 该指令需要传递一个有
   *
   * ```ts
   * interface appiconType {
   *  id: string,
   *  icon: string,
   * }
   * ```
   *
   * 类型的数据来拼接 `app-icon`
   *
   *
   */
  static APP_ICON = 'appicon'

  /**
   * 显示时间
   *
   * => 刚刚
   * => XX分钟前
   */
  static TIME = 'time'

  /**
   * 头像
   */
  static AVATAR = 'avatar'
}
