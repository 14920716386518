import { onIonViewWillEnter } from '@ionic/vue'
import { getCurrentInstance, ref } from 'vue'
import type { Router } from 'vue-router'

const isRouterBack = ref(true)

function isIOS() {
  var userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export function hookRouterBack(router: Router) {
  if (!isIOS()) return
  const oldIsBack = router.back.bind(router)
  router.back = () => {
    isRouterBack.value = true
    oldIsBack()
  }
  const oldIsGo = router.go.bind(router)
  router.go = (delta: number) => {
    isRouterBack.value = true
    oldIsGo(delta)
  }
}

/**
 * copy by https://tower.im/teams/750316/repository_documents/25418
 */
export function useIosGestureBack() {
  if (isIOS()) {
    const instance = getCurrentInstance()
    onIonViewWillEnter(() => {
      if (!isRouterBack.value) {
        const _el = instance?.vnode.el
        if (_el?.classList.contains('ion-page-hidden')) {
          const pre_el = _el.parentElement?.querySelector(':scope > .ion-page:not([aria-hidden])')
          pre_el?.style.setProperty('display', 'none')
          _el.style.setProperty('opacity', '1')
          _el.classList.remove('ion-page-hidden')
        }
      }
      isRouterBack.value = false
    })
  }
}
