<template id="ShopTab">
  <ion-page class="relative h-full">
    <ion-header class="ion-no-border">
      <ion-toolbar
        style="--padding-start: 16px; --padding-end: 16px; --padding-top: 0"
        :style="{
          '--min-height': ios ? `46px` : '',
        }"
      >
        <div class="flex w-full items-center justify-between">
          <div @click.stop="hdlClickSeachInput" class="flex grow items-center w-[260px] h-[40px] px-[15px] mr-[28px] bg-[#f7f8f9] rounded-[20px]">
            <div class="mr-[7px]">
              <SvgIcon name="搜索" :width="15" :height="15" color="#A5A5A5" />
            </div>
            <div class="w-full">
              <div ref="searchInput" class="bg-[#f7f8f9] w-full leading-[30px]">
                <span class="text-[14px] text-[#A5A5A5]">搜索</span>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div v-if="$$p" class="mr-[16px] relative" @click="$router.push({ name: 'shop-updateapplist' })">
              <div
                v-show="currentNeedUpdateApp"
                class="absolute left-[11px] top-[-7px] bg-[red] flex items-center justify-center border-1px border-white h-[14px]"
                :class="`${currentNeedUpdateAppCount < 10 && 'w-[14px] rounded-full'} ${currentNeedUpdateAppCount >= 10 && 'rounded-[10px] px-[2px]'}`"
              >
                <span class="text-[12px] scale-[.83333] leading-[10px] font-normal text-white break-keep">
                  {{ currentNeedUpdateAppCount > 99 ? '99+' : currentNeedUpdateAppCount }}
                </span>
              </div>

              <SvgIcon name="应用更新" :width="20" :height="20" />
            </div>
            <div @click="$router.push({ name: 'shop-category' })">
              <SvgIcon name="分类" :width="18" :height="18" />
            </div>
            <Safearea />
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <recommend-vue key="recommend" />
  </ion-page>
</template>

<script setup lang="ts">
import { IonHeader, IonPage, IonToolbar, onIonViewWillEnter } from '@ionic/vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { useRoot } from '@/store'
import SvgIcon from '@comp/SvgIcon.vue'
import Safearea from '@mp/Safearea.vue'
import RecommendVue from './Recommend.vue'

import { isIOS } from '@/utils/env'

import { useIosGestureBack } from '@hooks/useIosGestureBack'
import { storeToRefs } from 'pinia'
useIosGestureBack()
const ios = isIOS()

const rootStateStore = useRoot()
const { currentNeedUpdateApp, currentNeedUpdateAppCount } = storeToRefs(rootStateStore)

const searchInput = ref<HTMLInputElement>()

const router = useRouter()

function hdlClickSeachInput() {
  router.push({ name: 'search' })
}

onIonViewWillEnter(async () => {
  await rootStateStore.init()
})
</script>
