import { createPinia } from 'pinia'
import { createApp } from 'vue'

import '@vant/touch-emulator'

import App from './App.vue'
import router from './router'

import injectDirective from '@/directives'
import WrapperWidget from '@comp/Wrapper.vue'

import './css/global.css'
import './css/tailwind.css'
// ionic
import { IonicVue } from '@ionic/vue'
// Core CSS required for Ionic components to work properly
import '@ionic/vue/css/core.css'
// /* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
// /* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
// mobile 1px polyfill
import '@/css/extends_font.css'
import '@/css/polyfill_1px.css'

import '@lazycatcloud/lzc-toolkit/dist/style.css'

import { $$devSiteMode, checkIsPublicDomain, isElectron } from '@/utils/env'

import 'virtual:svg-icons-register'

// add markdown preview plugin
// link: https://code-farmer-i.github.io/vue-markdown-editor
// @ts-ignore
import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
// @ts-ignore
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'

import { hookRouterBack } from '@hooks/useIosGestureBack'

VMdPreview.use(vuepressTheme)
hookRouterBack(router)

function letRemoveScrollbar() {
  if (isElectron()) {
    document.querySelector('#app')?.classList.add('hidebar')
  }
}

const app = createApp(App)

app.config.globalProperties.$$p = !checkIsPublicDomain()
app.config.globalProperties.$$devSiteMode = $$devSiteMode ?? false
app.provide('$$$devSiteMode', $$devSiteMode)

app.use(VMdPreview)

app.use(createPinia())

injectDirective(app)
app.component('wrapper', WrapperWidget)

app.use(IonicVue, { mode: 'md' })

app.use(router)
window.$$$$router = router //注入全局，给 lzc-search-page 使用

router.isReady().then(() => {
  letRemoveScrollbar()
  app.mount('#app').$nextTick(() => postMessage({ payload: 'removeLoading' }, '*')) // 和electron交互，为了删除preload界面
})
