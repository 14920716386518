<template>
  <ion-content>
    <div class="pt-[14px] pb-[24px]">
      <template v-for="(item, index) in easyMapIndexData">
        <div
          v-if="item.type == LazycatAppTopicType.image"
          class="w-full swiper-custom"
          :style="{
            marginTop: !index ? '0px' : '32px',
            marginBottom: !index && index < easyMapIndexData.length - 1 ? '0px' : '12px',
          }"
          ref="divs"
        >
          <swiper
            :slidesPerView="'auto'"
            resize-observer
            update-on-window-resize
            auto-height
            loop
            :centered-slides="!index && false"
            :modules="[Autoplay]"
            :autoplay="{
              delay: 5e3,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }"
            @slideChange="handleSlideDidChange($event, item.id)"
          >
            <swiper-slide
              zoom
              v-for="subItem in item.imageAppList"
              :style="{
                width: !index ? '' : '66%',
                paddingLeft: '16px',
              }"
            >
              <div
                :class="[`rounded-[12px] overflow-hidden scale-[42px] w-full flex flex-col sm:h-[200px]`, !index ? 'h-[165px]' : 'h-[138px]']"
                @click="handleClickSlideOnceItem(subItem, !index)"
                :style="{
                  background: renderBG(subItem),
                  backgroundColor: `#D8D8D8`,
                }"
              ></div>

              <div
                class="flex flex-col w-full text-left pr-2 mt-[2px]"
                :style="{
                  marginBottom: 0, // '32px',
                }"
              >
                <p v-if="!index" class="text-[#999999] text-[12px] mt-[13px] mb-[3px]">
                  {{ subItem.subTitle }}
                </p>
                <h2
                  :style="{
                    marginTop: index ? '7px' : 0,
                    fontSize: index ? '16px' : '20px',
                  }"
                  class="text-[#202020] m-0 font-medium"
                >
                  {{ subItem.title }}
                </h2>
              </div>
            </swiper-slide>
          </swiper>

          <!-- <wrapper class="mb-[20px]">
            <div class="border_1px"></div>
          </wrapper> -->
        </div>

        <wrapper v-else>
          <div class="border_1px mb-[24px] mt-[32px]"></div>
          <div>
            <div class="flex justify-between">
              <div class="flex items-center">
                <h3 class="text-[20px] p-0 m-0 font-semibold">
                  {{ item.title }}
                </h3>
              </div>
              <div v-if="item.isMore" @click="handleClickOnceTopicAll(item)" class="flex items-end text-[12px] text-[#999999]">
                <div class="flex items-center justify-center">
                  <span class="relative">全部</span>
                  <div class="w-[5px]"></div>
                  <img src="@/assets/路径.svg" class="w-[6px] h-[10px]" />
                </div>
              </div>
            </div>
          </div>

          <div class="max-w-full" v-for="subItem in item.resultList">
            <AppCard
              v-for="(child, index) in subItem"
              :style="{
                marginTop: !index ? '20px' : 0,
              }"
              class="mb-[16px]"
              :app-info="child as any"
            />
          </div>
        </wrapper>
      </template>
    </div>
  </ion-content>
</template>

<script lang="ts" setup>
import { IonContent, onIonViewWillEnter } from '@ionic/vue'
import Hammer from 'hammerjs'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { Autoplay } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'

import AppCard from '@mp/AppCard.vue'

import { getIndex, getIndexWithCache, getRemoteTag } from '@/api'
import { chunk } from '@/utils/loadsh'
import { LazycatAppArticleModel, LazycatAppIndex, LazycatAppMetaModel, LazycatAppTopicType, RouterGoListActionType, TopicList } from '@type/models'

import { safeRun } from '@/utils/env'
import { sleep } from '@/utils/sleep'
import { LzcApp } from '@lazycatcloud/sdk/dist/extentions'

import { RES_PREFIX } from '@/config'
import { useRoot } from '@/store'
import { setControlViewVisibility } from '@/utils/internal_lzc_app_ext'

const divs = ref<HTMLDivElement[]>([])

const root = useRoot()

const router = useRouter()
const route = useRoute()

const slideCurrentIndexGroup = ref<Map<string, number>>(new Map())

const easyIndexData = ref<LazycatAppIndex>()

function renderBG(subItem: any): string {
  const img = subItem.mobileCover || subItem.coverImage_Mobile || subItem.coverImage
  return `url("${img}")  no-repeat center/cover`
}

watch(easyIndexData, (newValue) => {
  const newList = newValue ?? []
  for (let index = 0; index < newList.length; index++) {
    const $el = newList[index]
    if ($el.type == LazycatAppTopicType.image) {
      const id = $el.id
      if (!slideCurrentIndexGroup.value.has(id)) {
        slideCurrentIndexGroup.value.set(id, 0)
      }
    } else {
      continue
    }
  }
})

const easyMapIndexData = computed(() => {
  const data = easyIndexData.value ?? ([] as TopicList[])

  // ===============这个世界太乱.....
  if (data.length >= 3) {
    const [chunk1, chunk2, chunk3] = data
    if (chunk1.type == LazycatAppTopicType.image && chunk2.type == LazycatAppTopicType.image) {
      data[1] = chunk3
      data[2] = chunk2
    }
  }
  // =============================

  const result = data.map((item) => {
    const onceItem = Object.assign([], item.appList) as LazycatAppMetaModel[]
    const list = onceItem.map((item: any) => {
      const { pkgId } = item
      const icon = `${RES_PREFIX}/apps/` + pkgId + '/' + 'icon.png'
      return {
        ...item,
        icon,
      }
    })
    const resultList = chunk(list, 2)
    return {
      ...item,
      resultList,
    }
  })
  return result
})

watch(easyMapIndexData, async (newVal) => {
  await nextTick()
  const $el = divs.value[0]
  if (!$el) return
  // FIXME: 销毁长按事件
  const $$ = new Hammer($el, { domEvents: true })
  $$.on('press', async function (_) {
    await nextTick()
    const swiperDiv = $el.querySelector('.swiper') as HTMLDivElement & { swiper: any }
    if (!swiperDiv) return
    const $swiper = swiperDiv.swiper
    setTimeout(() => {
      // refer: https://github.com/nolimits4web/swiper/blob/74c891f65fa71f221d769a24f12cf7581fb8cbcb/src/modules/autoplay/autoplay.mjs#L204-L214
      $swiper.autoplay.run()
      $swiper.autoplay.paused = false
    }, 420)
  })
})

onMounted(beforeHook)

onIonViewWillEnter(() => {
  safeRun(
    async () => {
      await sleep(420)
      const isDisable = route.query.disable == 'true'
      if (!isDisable) {
        setControlViewVisibility(true)
      }
    },
    {
      isAndroid: true,
      privateApi: false,
    }
  )
  if (!LzcApp.isAndroidWebShell()) {
    safeRun(
      () => {
        root.updateAppCache()
      },
      {
        privateApi: true,
      }
    )
  }
})

async function getIndexOnPage(...args: Parameters<typeof getIndex>) {
  easyIndexData.value = await getIndex(...args).catch(() => {
    console.error('首页获取内容出错: ', args)
    return []
  })
}

async function beforeHook() {
  let release = ''
  const qs = route.query['release']
  if (!Array.isArray(qs) && !!qs) {
    release = qs
  }
  const cacheIndex = getIndexWithCache()
  const cacheRelease = cacheIndex?.index || ''
  // 发布预览直接请求对应tag
  if (release) {
    await getIndexOnPage(release, false)
  } else {
    // 先尝试加载缓存
    await getIndexOnPage(cacheRelease, true, true)
  }
  if (!release) {
    const remoteTag = await getRemoteTag()
    const isUpdate = remoteTag != cacheRelease
    if (isUpdate) {
      await getIndexOnPage(remoteTag, false, true)
    }
  }
}

async function handleSlideDidChange($el: any, id: string) {
  const { activeIndex } = $el
  slideCurrentIndexGroup.value.set(id, activeIndex)
}

async function handleClickSlideOnceItem(item: LazycatAppArticleModel & { appid?: string }, gotoAppDetail: boolean = false) {
  const id = gotoAppDetail ? item!.appid : item.id
  if (gotoAppDetail) {
    router.push(`/shop/detail/${id}`)
  } else {
    router.push(`/shop/topic/detail/${id}`)
  }
}

async function handleClickOnceTopicAll(item: TopicList) {
  const { title, id, type: _type } = item
  if (_type == LazycatAppTopicType.image) return
  router.push({
    name: 'shop-category-detail',
    params: {
      id,
    },
    query: {
      title,
      action: RouterGoListActionType.topicList,
    },
  })
}
</script>

<style>
.swiper-custom .swiper-slide {
  width: 92%;
}
</style>
