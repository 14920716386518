import { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@p/Layout.vue')
const Recommend = () => import('@p/Recommend.vue')

const CategoryHome = () => import('@p/CategoryHome.vue')
const CategoryDetail = () => import('@p/CategoryDetail.vue')

const UpdateAppList = () => import('@p/UpdateAppList.vue')
const TopicDetail = () => import('@p/TopicDetail.vue')
const SearchPage = () => import('@p/AppSearch.vue')
const AppDetailPage = () => import('@p/AppDetail.vue')
const CommentList = () => import('@p/CommentList.vue')

export default <RouteRecordRaw[]>[
  {
    path: '/',
    redirect: '/shop',
  },
  {
    component: Layout,
    path: '/shop',
    name: 'shop-home',
    meta: {
      desc: 'PC',
    },
    children: [
      {
        path: '',
        name: 'shop-recommend',
        component: Recommend,
        props: (route) => route.query,
      },
      {
        path: 'category',
        name: 'shop-category',
        component: CategoryHome,
      },
    ],
  },
  {
    path: '/shop/category/:id',
    name: 'shop-category-detail',
    component: CategoryDetail,
    props: true,
  },
  {
    path: '/shop/updateapplist',
    name: 'update-app',
    component: UpdateAppList,
  },
  {
    path: '/shop/topic/detail/:id',
    name: 'shop-topic-detail',
    component: TopicDetail,
    props: true,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
    meta: {
      desc: 'PC',
    },
  },
  {
    path: '/shop/detail/:id',
    name: 'appDetail',
    component: AppDetailPage,
    props: true,
  },
  {
    path: '/shop/comment/:id',
    name: 'comment-list',
    component: CommentList,
    props: true,
  },
]
