<template>
  <button :id="appName" :appId="appId" @click.stop="handleClick" v-if="canBeShow" :style="calcWrapperStyle" class="wrapper">
    <span class="mask" :style="calcMaskStyle"></span>
    <!-- <span v-else class="put"><em v-text="calcText"></em></span> -->
    <p v-text="calcText" />
  </button>
  <div v-if="isChecking" class="w-[62px] h-[28px] flex items-center justify-center">
    <div class="w-[24px] h-[24px] border-b-[#2ec1cc] rounded-full border-[2px] animate-loading"></div>
  </div>
</template>

<script setup lang="ts">
import { AppInfo, AppStatus } from '@lazycatcloud/sdk/dist/sys/package_manager'
import { computed, CSSProperties, ref } from 'vue'

import { checkAllowInstallApp, getCurrentDeviceOS, getSysVersion, openInstalledApp } from '@/api'

import { checkIsPublicDomain, safeOpen } from '@/utils/env'
import { sleep } from '@/utils/sleep'

import { showToast, useAccessControler, usePermission, useRoot } from '@/store'
import { storeToRefs } from 'pinia'
import semver from 'semver'

const { installable } = storeToRefs(usePermission())
const { table } = storeToRefs(useAccessControler())
const { visibleable } = useAccessControler()

const PP = !checkIsPublicDomain()

const kMockAuthTime = 720
const kMainColor = `#2ec1cc`

const props = defineProps({
  appId: {
    type: String,
    required: true,
  },
  inDetail: {
    type: Boolean,
    default: false,
  },
  appName: {
    type: String,
    required: false,
  },
  osDependence: {
    type: String,
    required: false,
  },
})

const root = useRoot()

const isChecking = ref<boolean>(false)

const visible = computed<boolean>(() => {
  return visibleable(props.appId)
})

const canBeShow = computed(() => {
  const inDetail = props.inDetail
  if (!visible.value && inDetail) return false
  if (!PP && inDetail) return false
  return !isChecking.value
})

const calcWrapperStyle = computed<CSSProperties>(() => {
  const data = root.getOnceAppStatus(props.appId)
  const status = data?.status ?? AppStatus.NotInstalled
  const { inDetail } = props
  const isInstalled = status == AppStatus.Installed
  const uninstalled = [AppStatus.Failed, AppStatus.NotInstalled, AppStatus.Paused].includes(status)

  let color = inDetail && isInstalled ? `#fff` : kMainColor
  let backgroundColor = inDetail && isInstalled ? kMainColor : status == AppStatus.Installing ? `rgb(230, 245, 248)` : `transparent`
  if (inDetail && uninstalled) {
    color = `#fff`
    backgroundColor = kMainColor
  }

  return {
    borderRadius: `18px`,
    width: `62px`,
    height: `28px`,
    border: `1px solid ${kMainColor}`,
    color,
    backgroundColor,
  }
})

const calcMaskWidth = computed<string | number>(() => {
  const renderText = root.getOnceAppButtonText(props.appId)
  let width: string | number = 0
  if (renderText.endsWith('%')) {
    width = renderText
  }
  return width
})

const calcMaskStyle = computed<CSSProperties>(() => {
  const width = calcMaskWidth.value
  return {
    width,
    backgroundColor: kMainColor,
    transition: width == 0 ? '' : `width 0.66s cubic-bezier(0.66, 0, 0.01, 1) 0s`,
  }
})

const calcText = computed<string>(() => {
  if (!PP) return `查看`
  if (!installable.value && visible.value) return `打开`
  if (!installable.value) return `查看`
  const text = root.getOnceAppButtonText(props.appId)
  if (text == '下载中' || text.endsWith('%')) return `下载中`
  return text
})

// 在安装/更新时，检查系统版本号是否匹配
async function matchOsVersion() {
  try {
    const upVersion = props.osDependence
    console.log('matchOsVersion - upVersion:%s', upVersion)
    if (!upVersion) return true
    // 如果应用要求的系统版本号校验失败，则不允许操作
    if (!semver.valid(upVersion)) return false
    const osVersion = await getSysVersion()
    console.log('matchOsVersion - osVersion:%s', osVersion)
    if (!osVersion) return true
    // 如果有osVersion且有upVersion则比对版本号，没有版本号的时候可以操作
    // 如果版本相等，则可以操作
    if (osVersion === upVersion) return true
    // 如果系统版本号校验失败，则可以操作
    if (!semver.valid(osVersion)) return true
    // 如果要求的版本号大于当前版本号，则版本不匹配不允许操作
    if (semver.gt(upVersion, osVersion)) return false
    return true
  } catch (e) {
    console.error('matchOsVersion error: ', e)
    return true
  }
}

async function handleClick() {
  const id = props.appId
  let action = root.getOnceAppStatus(id)
  if (!action) {
    action = {
      appid: id,
      status: AppStatus.NotInstalled,
    } as AppInfo
  }
  if (!table.value.noLimit && table.value.allowAccessAppids.includes(id)) {
    openApp(action)
    return
  }
  if (!PP || !installable.value) {
    const id = props.appId
    safeOpen(`/shop/detail/${id}`)
    return
  }
  const { status } = action

  // FIXME: 这里可能不需要了
  // matchOsVersion 在这里进行比对
  const isRequest = await beforeRequestAuth(action)
  if (isRequest) return

  switch (status) {
    /**
     * 未安装和失败同样都是安装操作
     */
    case AppStatus.Failed:
    case AppStatus.NotInstalled: {
      // 检查系统版本号
      root.installApp(id)
      return
    }

    // 打开 | 更新操作
    case AppStatus.Installed: {
      const isUpdate = root.checkAppIsCanUpdate(id)
      if (isUpdate) {
        // 检查系统版本号
        root.updateApp(id)
      } else {
        openApp(action)
      }
      return
    }

    // 暂停
    case AppStatus.Paused: {
      root.resumeInstallApp(id)
      return
    }
    // 下载中
    case AppStatus.Downloading: {
      root.pauseInstallApp(id)
      return
    }
  }
}

async function openApp(appInfo: AppInfo): Promise<void> {
  const { domain, appid, unsupportedPlatforms } = appInfo
  if (!domain) return
  let os = await getCurrentDeviceOS()
  if (os == 'darwin') {
    os = 'macos'
  }
  if (unsupportedPlatforms.includes(os)) {
    showToast('该应用暂不支持该平台', 1600)
    return
  }
  await openInstalledApp(appid, domain)
}

async function beforeRequestAuth(app: AppInfo | undefined): Promise<boolean> {
  if (!app) return false
  const status = app.status
  const fk: AppStatus[] = [AppStatus.NotInstalled, AppStatus.Paused, AppStatus.Failed]
  if (fk.includes(status)) {
    let isNext = false
    isChecking.value = true
    try {
      await sleep(kMockAuthTime)
      isNext = await checkAllowInstallApp()
    } catch (error) {
      console.error('request auth fail: ', error)
    }
    isChecking.value = false
    if (!isNext) {
      showToast('暂无权限，请联系管理员安装', 1000)
      return true
    }
  }

  if ([AppStatus.Failed, AppStatus.NotInstalled, AppStatus.Installed].includes(app.status)) {
    // 安装操作 - 检查系统版本号是否匹配
    const isMatchOsVersion = await matchOsVersion()
    if (!isMatchOsVersion) {
      showToast('当前版本不兼容，请前往更新系统版本', 1200)
      return true
    }
  }

  if ([AppStatus.Failed, AppStatus.NotInstalled, AppStatus.Paused].includes(app.status)) {
    root.setAppDownloadStatus(app.appid)
  }
  return false
}
</script>

<style scoped>
.wrapper {
  position: relative;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
}

.wrapper p {
  position: relative;
  z-index: 3;
}

.wrapper span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  opacity: 0.15;
}

.wrapper span.put {
  position: absolute;
  width: v-bind(calcMaskWidth);
  background: #2ec1cc;
  color: white;
  opacity: 1;
  z-index: 4;
  transition: all 0.3s;
}
.wrapper span.put em {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  width: 62px;
  height: 28px;
}
</style>
