import Home from '@m/Home/Layout.vue'
import { LocationQueryValue, RouteRecordRaw } from 'vue-router'

const TopicDetail = () => import('@m/TopicDetail.vue')
const ShopCategory = () => import('@m/Category.vue')
const ShopCategoryList = () => import('@m/CategoryList.vue')
const ShopDetail = () => import('@m/AppDetail.vue')
const ShopScore = () => import('@m/AppComment.vue')
const ShopUpdateAppList = () => import('@m/UpdateAppList.vue')
const ShopChangelog = () => import('@m/UpdateLogs.vue')
const SearchIndex = () => import('@m/Search/index.vue')
const SearchResult = () => import('@m/Search/SearchResult/index.vue')

function pickFirst(raw: Array<LocationQueryValue> | LocationQueryValue): string {
  if (Array.isArray(raw)) return raw[0] ?? ''
  return raw ?? ''
}

export default <RouteRecordRaw[]>[
  {
    path: '/',
    redirect: '/shop',
  },
  {
    path: '/shop',
    name: 'shop-home',
    component: Home,
    meta: {
      title: '懒猫应用商店',
    },
  },
  {
    path: '/shop/topic/detail/:id',
    name: 'shop-topic-detail',
    component: TopicDetail,
    meta: {
      title: '专题详情',
    },
    props: true,
  },
  {
    path: '/shop/category',
    name: 'shop-category',
    component: ShopCategory,
    meta: {
      title: '分类',
    },
  },
  {
    path: '/shop/category/:id',
    name: 'shop-category-detail',
    component: ShopCategoryList,
    meta: {
      title: '分类列表',
    },
    props: true,
  },
  {
    path: '/shop/detail/:id',
    name: 'shop-detail',
    component: ShopDetail,
    meta: {
      title: '应用详情',
    },
    props: true,
  },
  {
    path: '/shop/score/:id',
    name: 'shop-score',
    component: ShopScore,
    meta: {
      title: '应用评分',
    },
    props: (router) => {
      let id = router.params.id
      let icon = router.query.icon
      return {
        id: pickFirst(id),
        icon: pickFirst(icon),
      }
    },
  },
  {
    path: '/shop/updateapplist',
    name: 'shop-updateapplist',
    component: ShopUpdateAppList,
    meta: {
      title: '待更新列表',
    },
  },
  {
    path: '/shop/changelog/:id',
    name: 'shop-changelog',
    component: ShopChangelog,
    meta: {
      title: '版本历史记录',
    },
    props: true,
  },
  { path: '/shop/search', name: 'search', component: SearchIndex },
  { path: '/search-result', name: 'search-result', component: SearchResult },
]
