import { $$devSiteMode } from '@/utils/env'
import { jumpLoginWithClient, LazycatAccountCenter } from '@lazycatcloud/public-account'
import { LzcApp } from '@lazycatcloud/sdk/dist/extentions'

export const loginServer = 'account.lazycat.cloud'

const publicAccountService = new LazycatAccountCenter({
  baseURL: 'https://account.lazycat.cloud/api',
})

export async function gotoLogin() {
  if ($$devSiteMode) {
    window.$wujie.bus.$emit('login')
  } else {
    const x = LzcApp.isAndroidWebShell()
    if (x) {
      const isLoginSuccess = await new Promise<boolean>((res) => {
        jumpLoginWithClient(async (isLoginSuccess) => {
          res(isLoginSuccess)
        })
      })
      if (isLoginSuccess) {
        console.log('[debug] 登录成功')
        await publicAccountService.syncToken()
        // location.reload()
      } else {
        console.log('[debug] 登录失败')
      }
      return
    }
    location.href = 'https://' + loginServer + `?from=${encodeURIComponent(location.href)}`
  }
}

export async function isLogin(): Promise<boolean> {
  try {
    const user = await publicAccountService.current()
    return !!user
  } catch (error) {
    return false
  }
}
