<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  setup(props) {
    const symbolId = computed(() => `#${props.prefix}-${props.name}`)
    return { symbolId }
  },
  name: 'SvgIcon',
  props: {
    prefix: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#fff',
    },
    width: {
      type: Number,
      default: 22,
    },
    height: {
      type: Number,
      default: 22,
    },
  },
})
</script>

<template>
  <svg
    aria-hidden="true"
    :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <use :xlink:href="symbolId" :fill="color" :color="color" />
  </svg>
</template>
