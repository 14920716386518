import { defineStore, storeToRefs } from 'pinia'
import { usePermission } from './usePermission'

import { SimpleToast } from '@/components/Toast'

import { boxInstallApp, pauseInstallApplication, queryBoxInstalledApplication, queryRepoAppInfo } from '@/api'
import { sleep } from '@/utils/sleep'

import { safeRun } from '@/utils/env'
import { AppInfo, AppStatus } from '@lazycatcloud/sdk/dist/sys/package_manager'
import type { LazycatAppMetaModel } from '@type/models'

import semver from 'semver'

// =========== copy by https://decipher.dev/30-seconds-of-typescript/docs/throttle/
let _lastFn: any = null
let _inThrottle = true
let _lastTime = Date.now()
const THROTTLE_TIME = 4e3 /** sync upstream time 4000 */
// ====================================

// 需要更新的常量
const UPDATE_MAGIC = 6

const kLoopTimer = 420

const kInstallText = `安装`
const kUpdateText = `更新`
const kOpenApp = '打开'
const kDownloading = '下载中'
// const kPrepareDownload = '开始下载'

let kToast: any = null
let $$kTimerMachine: any = null

const AppStatusMap = new Map<number, string>([
  [AppStatus.NotInstalled, kInstallText],
  [AppStatus.Downloading, '正在下载'],
  [AppStatus.Paused, '暂停'],
  [AppStatus.Installing, '安装中'],
  [AppStatus.Installed, '已安装'],
  [AppStatus.Failed, '未知错误'],
  [UPDATE_MAGIC, kUpdateText],
  // [AppStatus.UNRECOGNIZED, '未知错误'],
])

/**
 * 安装应用之后执行`循环查询任务状态`
 * 当遇到这几个状态会直接停止任务
 */
const kStopInstallAppStatusActions = [
  AppStatus.UNRECOGNIZED, // 未知错误
  AppStatus.NotInstalled, // 未安装
  AppStatus.Paused, // 暂停
  AppStatus.Installed, // 已安装
  AppStatus.Failed, // 安装失败
]

/**
 * 将数据通过固定 `key` 转为 `Map`
 * @param raw 原数组
 * @param key map<K>
 */
function trans2Map<T extends Record<Key, string>, Key extends keyof T>(raw: T[], key: Key): Map<string, T> {
  const result = new Map<string, T>()
  raw.forEach((item) => {
    const k = item[key]
    result.set(k, item)
  })
  return result
}

export function showToast(msg: string, duration = 1200) {
  if (!!kToast) kToast.destory() /** show more toast */
  kToast = SimpleToast({
    value: msg,
    duration,
  })
}

function easyHumanProgressText(data: AppInfo) {
  const { downloadProgress } = data
  if (!downloadProgress) return kDownloading
  const { current, total } = downloadProgress
  if (total <= 0 || current <= 0) return kDownloading /*return kPrepareDownload*/
  const perc = +(current / total)
  if (Number.isNaN(perc)) {
    return kDownloading /*kPrepareDownload*/
  }
  const humanText: string = (perc * 100).toFixed(0)
  return humanText + '%'
}

interface IRootStore<T, M> {
  /**
   * App缓存表
   */
  appMap: Map<string, T>

  /***
   * 从上游拿到的更新数据表
   */
  upstreamMap: Map<string, M>

  /**
   * 追踪的应用集合
   */
  trackApps: Set<string>

  /**
   * 更新的缓存机制..
   */
  updateActionDep: Set<string>
}

/**
 * @deprecated 很蠢的方式, 将过往的数据缓存起来
 */
const upstreamPatchs = new Map<string, LazycatAppMetaModel>()

function reCheckAppUpdateState(streamApp?: LazycatAppMetaModel, boxApp?: AppInfo) {
  if (!boxApp || !streamApp) return false
  // 如果应用的版本号不符合 semver 规范
  // 则使用原始的比对版本号流程
  if (streamApp.version == boxApp.version) return false
  if (!semver.valid(streamApp.version) || !semver.valid(boxApp.version)) {
    return boxApp.version != streamApp.version
  }
  const result = semver.gt(streamApp.version, boxApp.version!)
  return result
}

export const useRoot = defineStore('root', {
  state: (): IRootStore<AppInfo, LazycatAppMetaModel> => ({
    appMap: new Map(),
    upstreamMap: new Map(),
    trackApps: new Set(),
    updateActionDep: new Set(),
  }),
  getters: {
    /**
     * 是否需要更新
     */
    currentNeedUpdateApp(): boolean {
      return this.currentNeedUpdateAppCount >= 1
    },
    /**
     * 返回需要更新的应用数量
     */
    currentNeedUpdateAppCount(): number {
      // 如果没有安装权限是不需要更新的
      const installable = storeToRefs(usePermission()).installable
      if (!installable.value) return 0
      return this.currentNeedUpdateApps.length
    },
    /**
     * 需要更新的应用
     */
    currentNeedUpdateApps(): LazycatAppMetaModel[] {
      const { appMap: lists, upstreamMap: metarepoLists } = this
      if (!lists.size || !metarepoLists.size) return []
      const result: LazycatAppMetaModel[] = []
      if (!!this.updateActionDep.size) {
        this.updateActionDep.forEach((key) => {
          let value = metarepoLists.get(key)
          if (!value) {
            value = upstreamPatchs.get(key)!
          }
          result.push(value!)
        })
      }
      lists.forEach((value, key) => {
        const metarepoData = metarepoLists.get(key)
        if (this.updateActionDep.has(key)) return
        if (!metarepoData) return
        if (!reCheckAppUpdateState(metarepoData, value)) return
        result.push(metarepoData)
      })
      result.sort((a, b) => {
        // re-sort array
        return a.name.localeCompare(b.name, 'zh-Hans-CN') // https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
      })
      return result
    },
  },
  actions: {
    /**
     * 初始化操作
     */
    async init() {
      await new Promise((res) => {
        safeRun(async () => {
          await this.updateAppCache()
          await this.updateUpstreamCache()
          res(true)
          this.trackAllStatus()
        })
      })
    },
    /**
     * 更新App缓存表
     */
    async updateAppCache(): Promise<boolean> {
      try {
        const data = await queryBoxInstalledApplication()
        // for (let i = 0; i < data.length; i++) {
        //   const v = data[i]
        //   if (v.appid === 'cloud.lazycat.app.photo') {
        //     v.status = AppStatus.Failed
        //   }
        // }
        const map = trans2Map(data, 'appid')
        // console.log('updateAppCache: ', data, map)
        this.appMap = map
        return true
      } catch (error) {
        console.error('fetch devices is error: ', error)
        return false
      }
    },
    /**
     * 设置某个应用为下载中的状态
     */
    setAppDownloadStatus(id: string) {
      let appInfo: any = this.appMap.get(id)
      if (!appInfo) {
        appInfo = {
          appid: id,
          status: AppStatus.Downloading,
          unsupportedPlatforms: [],
        }
      }
      appInfo.status = AppStatus.Downloading
      this.appMap.set(id, appInfo)
    },
    /**
     * 更新上游缓存表
     */
    async updateUpstreamCache(fetchAll = false, depScope = false): Promise<void> {
      const { appMap: lists } = this
      let tables: string[] = []
      if (fetchAll) {
        if (depScope) {
          tables = Array.from(this.updateActionDep.keys())
        } else {
          tables = Array.from(lists.keys())
        }
      } else {
        lists.forEach((value, key) => {
          // 本地应用状态为安装失败了，也需要获取云端版本号进行比对
          if ([AppStatus.Installed, AppStatus.Failed].includes(value.status)) {
            tables.push(key)
          }
        })
      }
      const waitUpdateAppLists = await queryRepoAppInfo(tables)
      waitUpdateAppLists.forEach((value) => {
        upstreamPatchs.set(value.pkgId, value)
      })
      const map = trans2Map(waitUpdateAppLists, 'pkgId')
      // console.log('线上列表: ', map)
      this.upstreamMap = map
    },
    async updateUpstreamCacheWithThrottle() {
      if (!_inThrottle) {
        _lastTime = Date.now()
        _inThrottle = true
      } else {
        clearTimeout(_lastFn)
        const wait = THROTTLE_TIME
        _lastFn = setTimeout(() => {
          if (Date.now() - _lastTime >= wait) {
            this.updateUpstreamCache()
            _lastTime = Date.now()
          }
        }, Math.max(wait - (Date.now() - _lastTime), 0))
      }
    },
    /**
     * 追踪所有应用状态并自动更新状态
     */
    trackAllStatus() {
      const trackStatus = [AppStatus.Installing, AppStatus.Downloading]
      this.appMap.forEach((item, key) => {
        const { status } = item
        const isNext = trackStatus.includes(status) && !this.isTrack(key)
        if (isNext) {
          this.trackOnceApp(key)
        }
      })
    },
    /**
     * 判断应用是否已经被追踪状态
     */
    isTrack(appID: string) {
      return !!this.trackApps.has(appID)
    },
    trackOnceApp(appID: string) {
      this.trackApps.add(appID)
      this.trackApp()
      return -1 /* fixme */
    },
    updateOnceAppStatus(id: string, data: AppInfo) {
      this.appMap.set(id, data)
    },
    /**
     * 轮询追踪所有操作的应用
     */
    async trackApp() {
      if (!!$$kTimerMachine) clearInterval($$kTimerMachine)
      $$kTimerMachine = setInterval(() => {
        this.updateFullAppStatus()
      }, kLoopTimer)
    },
    async updateFullAppStatus() {
      const trackApps = Array.from(this.trackApps)
      if (!trackApps.length) return /* ignore empty track task */
      const $currentStatus = await queryBoxInstalledApplication(trackApps)
      $currentStatus.forEach((item) => {
        const { status, appid } = item
        if (kStopInstallAppStatusActions.includes(status)) {
          this.updateActionDep.delete(appid)
          this.trackApps.delete(appid)
          if (!this.trackApps.size) {
            clearInterval($$kTimerMachine)
          }
          if (status !== AppStatus.Installed) {
            // FIXME: 安装失败之后之前的应用就被卸载了 :(
            showToast(`安装失败(状态码: ${status})`)
          }
        }
        this.updateOnceAppStatus(item.appid, item)
      })
      this.updateUpstreamCacheWithThrottle()
      return $currentStatus
    },
    async updateOnceAppStatusWithRemote(appID: string): Promise<AppInfo> {
      const $currentStatus = await queryBoxInstalledApplication(appID)
      this.updateOnceAppStatus(appID, $currentStatus)
      return $currentStatus
    },
    getOnceAppStatus(id: string) {
      return this.appMap.get(id)
    },
    getOnceAppUpStream(id: string) {
      return this.upstreamMap.get(id)
    },
    getOnceAppButtonText(id: string): string {
      const data = this.getOnceAppStatus(id)
      const status: number = data?.status ?? 0
      if (!data) return kInstallText
      switch (status) {
        case AppStatus.Installed:
          if (this.checkAppIsCanUpdate(id)) return kUpdateText
          return kOpenApp
        case AppStatus.Failed: // NOTE(d1y): 失败同样显示的是安装
          // 如果状态失败了，并且在需要更新列表里面，则展示更新
          const needUpdateAppIds = this.currentNeedUpdateApps.map((v) => v.pkgId)
          if (needUpdateAppIds.includes(id)) {
            return kUpdateText
          }
          return kInstallText
        case AppStatus.Downloading:
          return easyHumanProgressText(data)
      }
      return AppStatusMap.get(status) ?? kInstallText
    },
    /**
     * 检测app是否以及安装(`_.status == AppStatus.Installed`)
     */
    checkAppIsInstalled(appID: string): boolean {
      const data = this.getOnceAppStatus(appID)
      if (!data) return false
      return data.status == AppStatus.Installed
    },
    checkAppIsCanUpdate1(appID: string): boolean {
      const boxApp = this.getOnceAppStatus(appID)
      const streamApp = this.upstreamMap.get(appID)
      return reCheckAppUpdateState(streamApp, boxApp)
    },
    /**
     * 检测app是否需要更新(比对版本号)
     */
    checkAppIsCanUpdate(appID: string): boolean {
      return this.checkAppIsCanUpdate1(appID)
    },
    /**
     * 安装应用
     */
    async installApp(appID: string): Promise<number> {
      const kWaitTimer = 120
      const repoAppInfo = await queryRepoAppInfo(appID)
      boxInstallApp(repoAppInfo)
      await sleep(kWaitTimer)
      await this.updateAppCache()
      await this.updateOnceAppStatusWithRemote(appID)
      return this.trackOnceApp(appID)
    },

    // ===================================== FIXME: 更新应用现在不可靠, 在安装之前需要检测之前任务的状态机 :^)
    /**
     * 更新应用
     */
    async updateApp(appID: string): Promise<boolean> {
      try {
        const isCanUpdate = this.checkAppIsCanUpdate(appID)
        if (!isCanUpdate) return false
        if (!this.upstreamMap.has(appID)) {
          await this.updateUpstreamCache(true, true)
        }
        this.updateActionDep.add(appID)
        await this.installApp(appID)
        return true
      } catch (error) {
        console.error('update app is fail: ', error)
        return false
      }
    },
    /**
     * 更新所有应用
     */
    async updateAllApp() {
      this.currentNeedUpdateApps.forEach((value) => this.updateApp(value.pkgId))
    },
    // =====================================

    /**
     * 暂停安装应用
     */
    async pauseInstallApp(appID: string): Promise<boolean> {
      const isPause = await pauseInstallApplication(appID)
      if (!isPause) return false
      this.updateActionDep.delete(appID)
      this.trackApps.delete(appID)
      this.updateOnceAppStatusWithRemote(appID)
      return isPause
    },
    /**
     * 继续安装应用
     */
    async resumeInstallApp(appID: string): Promise<number> {
      return await this.installApp(appID)
    },
    /**
     * 清除所有的定时器任务
     */
    cleanAllTask() {
      clearInterval($$kTimerMachine)
    },
  },
})
