import { RES_PREFIX } from '@/config'
import { LazycatAppMetaModel } from '@type/models'
import { DirectiveNamespace } from '../namespace'

import type { App, Directive } from 'vue'

function splicingIconImageUrl(appID: string, absPath: string): string {
  return `${RES_PREFIX}/apps/${appID}/${absPath}`
}

function exec(el: any, context: LazycatAppMetaModel | null, init = false) {
  const $el = el as HTMLImageElement
  if (init) {
    $el.src = ''
    return
  }
  const _ = context as Partial<LazycatAppMetaModel>
  if (!_) return
  const { pkgId, iconPath } = _
  if (!pkgId || !iconPath) return
  const path = splicingIconImageUrl(pkgId, iconPath)
  $el.src = path
}

const directive = <Directive>{
  beforeMount($el, { value }) {
    exec($el, value)
  },
  beforeUpdate($el, { value }) {
    exec($el, value)
  },
  unmounted($el) {
    exec($el, null, true)
  },
}

export default (vue: App) => {
  vue.directive(DirectiveNamespace.APP_ICON, directive)
}
