import { LzcApp } from '@lazycatcloud/sdk/dist/extentions'
import { onMounted, onUnmounted } from 'vue'

interface ContentVisibleEvent
  extends CustomEvent<{
    type: string
    msg: string
  }> {}

export default (fn: Function) => {
  if (!LzcApp.isAndroidWebShell()) return
  const kEventName = 'main_app_api'
  const kVisibleName = 'contentVisible'

  const changeEvent = (event: ContentVisibleEvent) => {
    const { detail } = event
    if (detail.type == kVisibleName) fn()
  }

  onMounted(() => {
    window.addEventListener(kEventName, changeEvent as any)
  })
  onUnmounted(() => {
    window.removeEventListener(kEventName, changeEvent as any)
  })
}
