<template>
  <div
    class="safearea-ios box-border"
    :style="{
      marginRight: ios ? px : 'unset',
    }"
  ></div>
</template>
<script setup lang="ts">
import { isIOS } from '@/utils/env'
const ios = isIOS()

const px = `12px`
</script>
